import { getIsStayStillAfterLogout } from "@/businesslogics/_common/login";
import {
  Anchor,
  GnbModelByPath,
} from "@/components/templates/gnbV3/types/gnbV3";
import { IN_HOUSE_URL } from "@/models/_common/urls";
import { logout } from "@teamsparta/auth-frontend";

export const sccAnchor: Anchor = {
  key: "scc",
  name: "스파르타코딩클럽",
  href: "/",
  brand: "온라인",
};

const nbAnchor: Anchor = {
  key: "nb",
  name: "내일배움캠프",
  href: "https://nbcamp.spartacodingclub.kr/",
  brand: "내일배움캠프",
};

export const hanghaeAnchor: Anchor = {
  key: "hanghae",
  name: "항해99",
  href: "https://hanghae99.spartacodingclub.kr/",
  brand: "항해",
};

export const bizAnchor: Anchor = {
  key: "bisness",
  name: "기업교육",
  href: "https://b2b.spartacodingclub.kr/",
  brand: "기업교육",
};

const intellipickAnchor: Anchor = {
  key: "intellipick",
  name: "스파르타 커리어",
  href: "https://intellipick.spartacodingclub.kr/",
  brand: "인텔리픽",
};

export const homeAnchor: Anchor = {
  key: "home",
  name: "홈",
  href: "/",
  brand: "온라인",
};

export const catalogAnchor: Anchor = {
  key: "catalogv3",
  name: "전체 강의",
  href: IN_HOUSE_URL.상품탐색,
  brand: "온라인",
};

export const aiClub = {
  key: "aitc",
  name: "AITC 자격증",
  href: "/aitc",
  brand: "온라인",
};

export const studyClub = {
  key: "study",
  name: "스터디클럽",
  href: "/study/hub",
  brand: "온라인",
};

export const blogAnchor: Anchor = {
  key: "blog",
  name: "블로그",
  href: "/blog",
  brand: "블로그",
};

export const freeboardAnchor: Anchor = {
  key: "freeboard",
  name: "게시판",
  href: "/community",
  brand: "커뮤니티",
};

export const communityAnchor: Anchor = {
  key: "community",
  name: "커뮤니티",
  href: "/blog",
  brand: "커뮤니티",
};

export const eventAnchor: Anchor = {
  key: "event",
  name: "이벤트",
  href: "/event",
  brand: "이벤트",
};

export const exhibitionAnchor: Anchor = {
  key: "exhibition",
  name: "수강생 작품",
  href: "/exhibition",
  brand: "수강생 작품",
};

export const faqAnchor: Anchor = {
  key: "faq",
  name: "고객센터",
  href: "https://support.spartacodingclub.kr/",
};

export const voucherAnchor: Anchor = {
  key: "voucher",
  name: "수강권",
  href: `${process.env.ONLINE_URL}/mypage/myvoucher`,
  isLoginNeededToNavigate: true,
};

export const registrationStatusAnchor: Anchor = {
  key: "registrationStatus",
  name: "국비 신청 내역",
  href: `/kdc/registration_status`,
  isLoginNeededToNavigate: true,
};

export const classroomAnchor: Anchor = {
  key: "classroom",
  name: "내 강의실",
  href: IN_HOUSE_URL.신_내강의실,
  isLoginNeededToNavigate: true,
};

export const homeworkFeedbackAnchor = {
  key: "homework",
  name: "숙제 피드백",
  href: `${process.env.ONLINE_URL}/homework/feedback`,
};

export const certificateAnchor: Anchor = {
  key: "certificate",
  name: "증명서",
  href: `${process.env.ONLINE_URL}/mypage/mycertificate`,
};

export const basicInfoAnchor: Anchor = {
  key: "basicInfo",
  name: "계정",
  href: `${process.env.ONLINE_URL}/mypage/mybasicinfo`,
};

export const logoutAnchor: Anchor = {
  key: "logout",
  name: "로그아웃",
  onClick: () => {
    logout({stay: getIsStayStillAfterLogout()});
  },
};

export const topAnchors = [
  sccAnchor,
  nbAnchor,
  hanghaeAnchor,
  bizAnchor,
  intellipickAnchor,
];

export const topRightAnchors = [faqAnchor];

export const mainAnchor = [
  aiClub,
  studyClub,
  catalogAnchor,
  freeboardAnchor,
  blogAnchor,
  eventAnchor,
  exhibitionAnchor,
];

export const mobileNavMainAnchor = [
  homeAnchor,
  aiClub,
  studyClub,
  catalogAnchor,
  communityAnchor,
];

export const mobileMyPageAnchors = [
  classroomAnchor,
  registrationStatusAnchor,
  voucherAnchor,
  certificateAnchor,
  homeworkFeedbackAnchor,
  basicInfoAnchor,
];

// mobile gnb
export const mobileGnbModelV3: GnbModelByPath = {
  nb: {
    pageName: "국비지원",
  },
  event: {
    pageName: "커뮤니티",
  },
  exhibition: {
    pageName: "커뮤니티",
  },
  community: {
    pageName: "커뮤니티",
  },
  blog: {
    pageName: "커뮤니티",
  },
  catalog: {
    pageName: "전체 강의",
  },
  aitc: {
    pageName: "AITC 자격증",
  },
  study: {
    pageName: "스터디클럽",
  },
  // kdc: {
  //   pageName: "국비 신청 내역",
  // },
};

export enum GnbHandleClickOption {
  BACK,
}
