import {
  isLoginModalShowAtom,
  nextPageAfterLoginAtom,
} from "@/stores/_common/modal";
import { useIsAuthenticated } from "@teamsparta/auth-frontend";
import { useAtom } from "jotai";

const addDomainToUrl = (url) => {
  if (!url.includes("://")) {
    url = process.env.BASE_URL + url;
  }
  return url;
};

const navigateToPage = (nextPage?: string) => {
  if (nextPage && typeof window !== "undefined") {
    window.location.href = addDomainToUrl(nextPage);
  }
};

export const useIsLoggedInV2 = () => {
  const { isNotAuthenticated } = useIsAuthenticated();
  return !isNotAuthenticated;
};

export const currentHref = () => {
  if (typeof window === "undefined") return null;
  return window.location.href;
};

export const currentPath = () => {
  if (typeof window === "undefined") return null;
  return window.location.pathname + window.location.search;
};

export const useWithLoginV2 = () => {
  const [isLoginModalShow, setIsLoginModalShow] = useAtom(isLoginModalShowAtom);
  const [nextPageAfterLogin, setNextPageAfterLogin] = useAtom(
    nextPageAfterLoginAtom,
  );
  const isURIEncoded = (str) => {
    try {
      const decoded = decodeURIComponent(str);
      return decoded !== str && encodeURIComponent(decoded) === str;
    } catch (e) {
      return false;
    }
  };

  return (nextPage?: string) => {
    nextPage =
      !!nextPage && !isURIEncoded(nextPage)
        ? encodeURIComponent(nextPage)
        : null;

    setNextPageAfterLogin(nextPage);
    setIsLoginModalShow(true);
  };
};

export const usePlainLoginV2 = () => {
  const withLoginV2 = useWithLoginV2();
  const currentAsNext = currentPath();

  return () => {
    withLoginV2(currentAsNext);
  };
};

export const useExecuteWithLoginV2 = () => {
  const withLoginV2 = useWithLoginV2();
  const isLoggedIn = useIsLoggedInV2();
  const currentAsNext = currentPath();

  return (
    callbackWithLogin: Function,
    callbackBeforeLoginV1?: Function,
    customNext?: string,
  ) => {
    if (isLoggedIn) {
      callbackWithLogin();
      return;
    }

    withLoginV2(customNext ?? currentAsNext);
  };
};

export const useNavigateWithLoginV2 = () => {
  const withLoginV2 = useWithLoginV2();
  const isLoggedIn = useIsLoggedInV2();

  return (nextPage?: string) => {
    if (isLoggedIn) {
      navigateToPage(nextPage);
      return;
    }

    withLoginV2(nextPage);
    return;
  };
};

// 로그인 필수 페이지들
const pagesWithLogin = [
  "/kdc/registration_status",
  "/kdc/check_card",
  "/kdc/study_payment",
  "/aitc/apply",
  "/community/myActivity",
];

export const getIsStayStillAfterLogout = () => {
  const path = window.location.pathname;

  return !pagesWithLogin.some((page) => path.includes(page));
};
