import { useEffect, useState } from "react";
import { useCookie } from "../window/cookie";

export const useUserId = () => useCookie("user_id");

export const useReferrer = () => {
  const [ref, setRef] = useState("");
  useEffect(() => {
    const { referrer } = document;
    setRef(referrer);
  }, []);
  return ref;
};

export const useWindow = () => {
  const [wind, setWind] = useState<Window>(null);
  useEffect(() => {
    setWind(window);
  }, []);
  return wind;
};

export const useUserAgent = () => {
  const [ua, setUa] = useState("");
  useEffect(() => {
    setUa(window.navigator.userAgent);
  }, []);
  return ua;
};

export const useLocation = () => {
  const [h, setHref] = useState<string>("");
  useEffect(() => {
    setHref(window.location.href);
  }, []);
  return h;
};

export const useUserInfo = (key: string) => {
  const userinfo = useCookie("userinfo");
  const [value, setValue] = useState("");
  useEffect(() => {
    if (typeof userinfo !== "undefined" && !!userinfo) {
      setValue(decodeURIComponent(userinfo.split(`${key}=`)[1]?.split("&")[0]));
    }
  }, [userinfo]);
  return value;
};

export const useIsLoggedIn = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const userId = useUserId();
  useEffect(() => {
    setIsLoggedIn(!!userId && !!userId.length);
  }, [userId]);
  return isLoggedIn;
};
